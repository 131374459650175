.terms {
  color: white;
  max-width: 95%;
  margin: 25px auto 75px;
  text-align: left;
  line-height: 1em;

  @media ($mobile) {
    max-width: 60%;
  }

  h1 {
    text-align: center;
    line-height: 1em;
  }

  li {
    padding-bottom: 15px;
  }
}