.header {
  background-color: $colorBG1;
  background-size: cover;
  position: relative;
  overflow: hidden;
  text-align: center;
  min-height: 460px;

  &.photo {
    background: white url('../../static/images/louise.jpg') 50% 60% no-repeat;
  }

  @media ($mobile-xs) {
    min-height: 600px;
  }

  @media ($mobile) {
    //min-height: 800px;
  }

  &-Wrapper {
    width: 80%;
    max-width: 1000px;
    margin: 80px auto 60px;
    overflow: hidden;
  }

  &-TitleWrapper {
    float: right;
    max-width: 300px;

    @media($desktop) {
      max-width: 400px;
    }

    @media($desktop-xl) {
      max-width: 500px;
    }
  }

  &-Sketch {
    float: left;
    max-width: 300px;

    @media($desktop) {
      max-width: 400px;
    }

    @media($desktop-xl) {
      max-width: 500px;
    }

    img {
      width: 100%;
    }
  }

  &-Title {
    text-align: center;
    max-width: 500px;

    img {
      width: 100%;
    }
  }

  &-Author {
    text-align: center;
    max-width: 450px;
    margin: 20px auto 0;

    img {
      width: 100%;
    }
  }

  &-Roundel {
    margin: 0 auto 60px;
    position: absolute;
    top: 50px;
    right: 50px;
    background-color: #d4135f;
    height: 110px;
    width: 110px;
    font-size: 30px;
    padding-top: 29px;
    border-radius: 50%;


    a {
      /* display: inline-block; */
      /* background-color: #d4135f; */
      /* border-radius: 25px; */
      color: #fff;
      /* padding: 7px 30px; */
      text-transform: uppercase;
      text-decoration: none;

      //display: inline-block;
      //background-color: #d4135f;
      //border-radius: 25px;
      //color: #fff;
      //padding: 7px 30px;
      //text-transform: uppercase;
      //text-decoration: none;
    }
  }
}