.submitted {
  background: linear-gradient(to bottom right, $colorBG1, $colorBG2);
  text-align: center;
  padding: 40px 5px;

  h1 {
    font-family: 'DragonflightPro' , sans-serif;
    line-height: 1.3em;
    font-size: 30px;

    @media($mobile) {
      font-size: 45px;
    }
  }

  &-Button {
    padding: 10px 20px;
    margin: 15px auto;
    border: none;
    background-color: $colorTint;
    color: white;
    display: inline-block;
    text-decoration: none;
  }
}