html, body {
  overflow-x: hidden;
}

/* http://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  color: $colorTint;
  font-family: 'LunchBox', sans-serif;
  font-size: 34px;
  background-color: $colorBG1;
}

main {
  margin: 0 auto;
  //width: 95%;
  //max-width: 1200px;
}

h1, h2, h3, p {
  margin: 0;
  line-height: 1em;
  font-weight: normal;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  //color: white;
}

input {
  -webkit-appearance: none;
  outline: none !important;
  border-radius: 0 !important;
}

.flag {
  margin-top: 30px;
  display: inline-block;
  max-width: 50px;
}

.errors {
  color: red;
}

.clear {
  clear: both;
}