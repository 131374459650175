.roseGold {
  background: linear-gradient(to bottom right, $colorBG1, $colorBG2);
  text-align: center;
  padding: 60px 5px;

  @media($mobile) {
    padding: 120px 40px;
  }

  h2 {
    font-family: 'DragonflightPro' , sans-serif;
    line-height: 1em;
    font-size: 50px;

    @media($mobile) {
      font-size: 90px;
      line-height: 1.3em;
    }
  }

  h3 {
    font-family: 'DragonflightPro' , sans-serif;
    line-height: 0.3em;
    font-size: 40px;

    @media($mobile) {
      font-size: 50px;
    }
  }
}

.synopsis {
  max-width: 800px;
  margin: 0 auto;
  font-size: 28px;
  overflow: hidden;

  &-Packshot {
    display: inline-block;
    margin: 0 35px 15px 0;
    max-width: 320px;
    float: none;

    @media ($mobile) {
      float: left;
    }
  }

  &-Text {
    margin: 0 0 25px 0;
    text-align: left;

    @media($mobile) {

    }
  }

  @media($mobile) {
    font-size: 34px;
    //text-align: center;

    &-Text--One {
      text-align: left;
    }

    &-Text--Two {
      padding-left: 50px;
      text-align: left;
    }

    &-Text--Three {
      padding-left: 100px;
      text-align: left;
    }

    &-Text--Four {
      padding-left: 150px;
      text-align: left;
    }
  }
}

.preorder {
  clear: both;

  &-Headline {
    margin: 20px 0 0;
  }

  &-SubHeadline {
    margin: 70px 0 10px;
  }

  &-Links {
    margin: 0 auto;
    max-width: 800px;

    img {
      max-height: 80px;
    }
  }

  &-Links--iBooks {
    max-width: 224px;
  }
}

.social {
  margin-top: 50px;

  &-Headline {
    margin-bottom: 10px;
    font-weight: bold;
  }
}

.signup {

  &-FormWrapper {
    max-width: 800px;
    background-color: white;
    border: solid 8px $colorTint;
    margin: 0 auto;
    padding: 10px;
  }

  &-Headline {
    font-size: 50px !important;
    font-family: 'AristelleSans-Condensed', sans-serif !important;
    margin-bottom: 10px;

    @media($mobile) {
      font-size: 70px !important;
    }

    span {
      text-decoration: underline;
    }
  }

  &-Form {
    padding: 10px;
    border: dotted 8px $colorTint;
    margin: 0;

    border-image-source: url('../images/dots.svg') !important;
    border-image-slice: 33% 33% !important;
    border-image-repeat: round !important;

    @media($mobile) {
      padding: 25px 100px;
    }

    li {
      margin: 15px 0;
    }

    label {
      text-transform: uppercase;
      font-family: 'AristelleSans-Condensed', sans-serif !important;
    }

    input[type=text], input[type=email] {
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      border-bottom: dotted 8px $colorTint;
      border-image-source: url('../images/dots.svg');
      border-image-slice: 33% 33%;
      border-image-repeat: round;
      color: #528CE0;

      text-align: center;
      width: 100%;
    }

    input[type=submit] {
      font-family: 'AristelleSans-Condensed', sans-serif !important;
      border: none;
      background-color: $colorTint;
      color: white;
    }
  }
}

.quote {
  text-align: left;
  width: 80%;
  max-width: 600px;
  margin: 0 auto;

  &-Image {

    text-align: center;

    img {
      border-radius: 50%;
      max-width: 200px;
      margin: 0;
    }
  }

  &-Text {
    position: relative;
    font-size: 24px;
    line-height: 1.2em;

    @media ($mobile) {
      font-size: 30px;
    }

    p {
      margin-bottom: 15px;

      span {
        font-family: 'Times New Roman', sans-serif;
        font-size: 20px;
      }
    }
  }
  &-LeftQuote {
    display: block;
    position: absolute;
    top: 0;
    left: -40px;

    img {
      width: 30px;

      @media ($mobile) {
        width: 45px;
      }
    }

    @media($mobile) {
      left: -60px;
    }
  }
  &-RightQuote {
    display: block;
    position: absolute;
    bottom: 0;
    right: -40px;

    img {
      width: 30px;

      @media ($mobile) {
        width: 45px;
      }
    }

    @media($mobile) {
      right: -60px;
    }
  }
  &-Attribution {
    text-align: right;
    font-family: 'DragonflightPro' , sans-serif;

    img {
      max-width: 100px;

      @media ($mobile) {
        max-width: 150px;
      }
    }
  }
}

.about {
  margin: 0 auto;
  max-width: 820px;

  &-Image {
    float: left;
    display: inline-block;
    margin: 10px 15px 15px 0;
    max-width: 290px;
  }

  &-Text {
    text-align: left;
    margin: 25px auto;
    font-size: 24px;
    line-height: 1.2em;

    span {
      font-family: 'Times New Roman', sans-serif;
      font-size: 20px;
    }

    @media ($mobile) {
      font-size: 30px;
    }

    &--Large {
      font-size: 30px;

      @media ($mobile) {
        font-size: 38px;
      }
    }
  }
}