.divider {
  font-family: 'AristelleSans-Condensed', sans-serif;
  text-align: center;
  height: 45px;
  overflow: hidden;
  position: relative;
  //border-bottom: 27px solid $colorTint;
  //background-color: black;
  background: $highlight;
  color: white;

  &-BG {
    position: absolute;
    z-index: -10;
    background-size: cover;
    height: 984px;
    width: 100%;

    img {
      width: 100%;
    }

    &--One {
      z-index: -10;

      @media($mobile) {
        top: -350%;
      }
    }

    &--Two {
      z-index: -20;

      @media($mobile) {
        top: -550%;
      }
    }

    &--Three {
      z-index: -30;

      @media($mobile) {
        top: -750%;
      }
    }

    &--Four {
      z-index: -40;

      @media($mobile) {
        top: -950%;
      }
    }
  }
}